import * as React from 'react'
import NewYear from "../../components/VideoPage/VideoPage";

const NewYear2022 = () => {
  return (
      <NewYear
        url="https://tfproductionmediaservice-euwe.streaming.media.azure.net/80546857-1202-416e-ad26-35247df65c59/Emso_2023_15secs_v1.ism/manifest"
      />
  )
}

export default NewYear2022;